export function modalSure(title) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    // this.$confirm(`确定${title || '删除该条数据吗'}?`, this.$t('提示'), {
    _this.$confirm(title ? _this.$t(title) : _this.$t('删除该条数据吗') + '?', _this.$t('提示'), {
      confirmButtonText: _this.$t('确定'),
      cancelButtonText: _this.$t('取消'),
      type: 'warning'
    }).then(function () {
      resolve();
    }).catch(function () {
      _this.$message({
        type: 'info',
        message: _this.$t('已取消')
      });
    });
  });
}
export function modalSureDelete(title) {
  var _this2 = this;
  return new Promise(function (resolve, reject) {
    // this.$confirm(`${title || '该记录删除后不可恢复，您确认删除吗'}?`, this.$t('提示'), {
    _this2.$confirm(title ? _this2.$t(title) : _this2.$t('该记录删除后不可恢复，您确认删除吗') + '?', _this2.$t('提示'), {
      confirmButtonText: _this2.$t('删除'),
      cancelButtonText: _this2.$t('不删除'),
      type: 'warning'
    }).then(function () {
      resolve();
    }).catch(function (action) {
      _this2.$message({
        type: 'info',
        message: _this2.$t('已取消')
      });
    });
  });
}
export function returnSure(title) {
  var _this3 = this;
  return new Promise(function (resolve, reject) {
    // this.$confirm(`确定${title || '删除该条数据吗'}?`, '提示', {
    _this3.$confirm(title ? _this3.$t(title) : _this3.$t('删除该条数据吗') + '?', _this3.$t('提示'), {
      confirmButtonText: _this3.$t('确定'),
      cancelButtonText: _this3.$t('取消'),
      type: 'warning'
    }).then(function () {
      resolve();
    }).catch(function () {
      _this3.$message({
        type: 'info',
        message: _this3.$t('已取消')
      });
    });
  });
}