import Layout from '@/layout';
import { roterPre } from '@/settings';
var systemFormRouter = {
  path: "".concat(roterPre, "/systemForm"),
  name: 'system',
  meta: {
    icon: 'dashboard',
    title: '设置'
  },
  alwaysShow: true,
  component: Layout,
  children: [{
    path: 'Basics/:key?',
    component: function component() {
      return import('@/views/systemForm/setSystem/index');
    },
    name: 'Basics',
    meta: {
      title: '店铺配置',
      noCache: true
    }
  }, {
    path: 'modifyStoreInfo',
    component: function component() {
      return import('@/views/systemForm/setSystem/modifyStoreInfo');
    },
    name: 'ModifyStoreInfo',
    meta: {
      title: '商户基本信息',
      noCache: true
    }
  }, {
    path: 'systemStore',
    name: 'setting_systemStore',
    meta: {
      title: '提货点设置'
    },
    component: function component() {
      return import('@/views/setting/systemStore/index');
    }
  }, {
    path: 'applyMents',
    name: 'ApplyMents',
    meta: {
      title: '申请分账商户'
    },
    component: function component() {
      return import('@/views/systemForm/applyMents/index');
    }
  }, {
    path: 'applyList',
    name: 'ApplyList',
    meta: {
      title: '分账商户列表'
    },
    component: function component() {
      return import('@/views/systemForm/applyMents/list');
    }
  }, {
    path: 'customer_keyword',
    component: function component() {
      return import('@/views/system/customer_keyword/index');
    },
    name: 'CustomerKeyword',
    meta: {
      title: '自动回复'
    }
  }, {
    path: 'openAuth/list',
    component: function component() {
      return import('@/views/setting/openAuth/list');
    },
    name: 'OpenList',
    meta: {
      title: '账户管理'
    }
  }]
};
export default systemFormRouter;