exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#6394F9",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#0B1529",
	"menuHover": "#182848",
	"subMenuBg": "#030C17",
	"subMenuHover": "#182848",
	"sideBarWidth": "180px"
};