import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import path from "path";
import { mapGetters, mapMutations, mapState } from "vuex";
import Logo from "./Logo";
import AppLink from "./Link";
import SidebarItem from "./SidebarItem";
import Item from "./Item";
import { isExternal } from "@/utils/validate";
import FixiOSBug from "./FixiOSBug";
import _variables from "@/styles/variables.scss";
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo,
    AppLink: AppLink,
    Item: Item
  },
  mixins: [FixiOSBug],
  data: function data() {
    this.onlyOneChild = null;
    return {
      sideBar1: window.localStorage.getItem("sidebarStyle") == "a" ? false : true,
      menu_name: "",
      list: this.$store.state.user.menuList,
      subMenuList: [],
      activePath: "",
      isShow: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(["permission_routes", "sidebar", "menuList"])), mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    sidebarRouters: function sidebarRouters(state) {
      return state.user.sidebarRouters;
    },
    sidebarStyle: function sidebarStyle(state) {
      return state.user.sidebarStyle;
    },
    // 所有的路由信息
    routers: function routers() {
      var routers = this.$store.state.user.menuList ? this.$store.state.user.menuList : [];
      return routers;
    }
  })), {}, {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  watch: {
    sidebarStyle: function sidebarStyle(newValue, oldValue) {
      this.sideBar1 = newValue == "a" && oldValue != "a" ? false : true;
      this.setMenuWidth();
    },
    sidebar: {
      handler: function handler(newVal, oldVal) {
        if (this.sideBar1) {
          this.getSubMenu();
        }
      },
      deep: true // 对象内部属性的监听，关键。
    },
    $route: {
      handler: function handler(newVal, oldVal) {
        if (this.sideBar1) {
          this.getSubMenu();
        }
      },
      deep: true // 对象内部属性的监听，关键。
    }
  },
  mounted: function mounted() {
    this.getMenus();
    if (this.sideBar1) {
      this.getSubMenu();
    } else {
      this.setMenuWidth();
    }
  },
  methods: _objectSpread({
    // 菜单选中后左侧宽度数值存储
    setMenuWidth: function setMenuWidth() {
      if (this.sideBar1) {
        if (this.subMenuList && this.subMenuList.length > 0 && !this.isCollapse) {
          this.$store.commit("user/SET_SIDEBAR_WIDTH", 270);
        } else {
          this.$store.commit("user/SET_SIDEBAR_WIDTH", 130);
        }
      } else {
        this.$store.commit("user/SET_SIDEBAR_WIDTH", 180);
      }
    },
    ishttp: function ishttp(url) {
      return url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1;
    },
    getMenus: function getMenus() {
      this.$store.dispatch("user/getMenus", {
        that: this
      });
    },
    hasOneShowingChild: function hasOneShowingChild() {
      var _this = this;
      var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var parent = arguments.length > 1 ? arguments[1] : undefined;
      var showingChildren = children.filter(function (item) {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          _this.onlyOneChild = item;
          return true;
        }
      });
      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }
      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = _objectSpread(_objectSpread({}, parent), {}, {
          path: "",
          noShowingChildren: true
        });
        return true;
      }
      return false;
    },
    resolvePath: function resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return routePath;
      }
      return path.resolve(routePath, routePath);
    },
    goPath: function goPath(item) {
      this.menu_name = item.menu_name;
      if (item.children) {
        this.$store.commit("user/SET_SIDEBAR_WIDTH", 270);
        // console.log(this.pathCompute(item.children)+ 'sdxsds');
        this.subMenuList = item.children;
        window.localStorage.setItem("subMenuList", this.subMenuList);
        var goUrl = this.resolvePath(this.getChild(item.children)[0].route);
        item.route = goUrl;
        this.$router.push({
          path: goUrl
        });
      } else {
        this.$store.commit("user/SET_SIDEBAR_WIDTH", 130);
        this.subMenuList = [];
        window.localStorage.setItem("subMenuList", []);
        var _goUrl = this.resolvePath(item.route);
        this.$router.push({
          path: _goUrl
        });
      }
    },
    getChild: function getChild(data) {
      var result = [];
      data.forEach(function (item) {
        // 遍历树
        var _loop = function loop(data) {
          var child = data.children;
          if (child) {
            // 是否有子节点，有则继续遍历下一级，无则是叶子节点
            for (var i = 0; i < child.length; i++) {
              _loop(child[i]);
            }
          } else {
            result.push(data);
          }
        };
        _loop(item);
      });
      return result;
    },
    //判断当前页面父级菜单
    pathCompute: function pathCompute(data) {
      var _this2 = this;
      console.log(this.$route);
      var _loop2 = function loop(child) {
        // 是否有子节点，有则继续遍历下一级，无则是叶子节点
        for (var i = 0; i < child.length; i++) {
          if (_this2.$route.path == child[i]['route'] || _this2.$route.meta.activeMenu == child[i]['route']) {
            return true;
          }
          if (_loop2(child[i].children || [])) {
            return true;
          }
          ;
        }
        return false;
      };
      if (!_loop2(data.children || [])) {
        return this.$route.path == data.route;
      }
      return true;
    },
    // 获取当前一级菜单的二级菜单
    getSubMenu: function getSubMenu() {
      for (var i = 0; i < this.menuList.length; i++) {
        if (this.pathCompute(this.menuList[i])) {
          this.subMenuList = this.menuList[i].children;
          this.menu_name = this.menuList[i].menu_name;
          window.localStorage.setItem("subMenuList", this.menuList[i].children);
          this.setMenuWidth();
          return;
        }
      }
    }
  }, mapMutations("user", ["SET_MENU_LIST"]))
};