//
//
//
//
//
//
//

import { getVersion as _getVersion } from '@/api/accounts';
export default {
  name: 'i-copyright',
  data: function data() {
    return {
      copyright: 'Copyright © 2023 浙江速云',
      openVersion: '0',
      copyright_status: '0',
      version: {}
    };
  },
  mounted: function mounted() {
    this.getVersion();
  },
  methods: {
    getVersion: function getVersion() {
      var _this = this;
      _getVersion().then(function (res) {
        var version = res.data.version;
        _this.version = res.data;
        _this.copyright = res.data.copyright;
        _this.openVersion = res.data.sys_open_version;
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    }
  }
};