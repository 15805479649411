import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import RightPanel from '@/components/rightPanel'
import { AppMain, Navbar, Settings, Sidebar, TagsView } from "./components";
import copyRight from '@/components/copyright';
import ResizeMixin from "./mixin/ResizeHandler";
import { getBaseInfo } from '@/api/user';
import { intervalTime } from '@/utils';
import { mapState } from 'vuex';
export default {
  name: 'Layout',
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    // RightPanel,
    Settings: Settings,
    Sidebar: Sidebar,
    TagsView: TagsView,
    copyRight: copyRight
  },
  mixins: [ResizeMixin],
  data: function data() {
    return {
      marginStatus: 0,
      days: 0
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    },
    sidebarWidth: function sidebarWidth(state) {
      return state.user.sidebarWidth;
    },
    sidebarStyle: function sidebarStyle(state) {
      return state.user.sidebarStyle;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  }),
  watch: {},
  mounted: function mounted() {
    var _this = this;
    getBaseInfo().then(function (res) {
      var resData = res.data;
      if (resData.margin_remind_status) {
        _this.days = intervalTime(Date.parse(new Date()) / 1000, resData.margin_remind_status);
        _this.marginStatus = 1;
      } else {
        _this.marginStatus = 0;
      }
    });
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    },
    goPay: function goPay() {
      this.$router.push({
        name: 'ModifyStoreInfo'
      });
    }
  }
};