var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.sideBar1 && _vm.isCollapse,
      class: { "has-logo": _vm.showLogo },
    },
    [
      _vm.showLogo
        ? _c("logo", {
            attrs: { collapse: _vm.isCollapse, sideBar1: _vm.sideBar1 },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        [
          !_vm.sideBar1
            ? _c(
                "el-menu",
                {
                  staticClass: "subMenu1",
                  attrs: {
                    "default-active": _vm.activeMenu,
                    collapse: _vm.isCollapse,
                    "background-color": _vm.variables.menuBg,
                    "text-color": _vm.variables.menuText,
                    "unique-opened": true,
                    "active-text-color": _vm.variables.menuActiveText,
                    "collapse-transition": false,
                    mode: "vertical",
                  },
                },
                [
                  _vm._l(_vm.menuList, function (route) {
                    return _c("sidebar-item", {
                      key: route.route,
                      attrs: { item: route, "base-path": route.route },
                    })
                  }),
                ],
                2
              )
            : [
                !_vm.isCollapse
                  ? _vm._l(_vm.menuList, function (item) {
                      return _c(
                        "ul",
                        { key: item.route, staticStyle: { padding: "0" } },
                        [
                          _c("li", [
                            _c("div", { staticClass: "menu menu-one" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "menu-item",
                                  class: { active: _vm.pathCompute(item) },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goPath(item)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    class: "menu-icon el-icon-" + item.icon,
                                  }),
                                  _c("span", [_vm._v(_vm._s(item.menu_name))]),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.subMenuList && _vm.subMenuList.length > 0 && !_vm.isCollapse
                  ? _c(
                      "el-menu",
                      {
                        staticClass: "menuOpen",
                        attrs: {
                          "default-active": _vm.activeMenu,
                          "background-color": "#ffffff",
                          "text-color": "#303133",
                          "unique-opened": false,
                          "active-text-color": "#303133",
                          mode: "vertical",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { height: "100%" } },
                          [
                            _c("div", { staticClass: "sub-title" }, [
                              _vm._v(_vm._s(_vm.menu_name)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-scrollbar",
                              { attrs: { "wrap-class": "scrollbar-wrapper" } },
                              _vm._l(_vm.subMenuList, function (itm, idx) {
                                return _c(
                                  "div",
                                  { key: idx },
                                  [
                                    _vm.hasOneShowingChild(itm.children, itm) &&
                                    (!_vm.onlyOneChild.children ||
                                      _vm.onlyOneChild.noShowingChildren) &&
                                    !itm.alwaysShow
                                      ? [
                                          _vm.onlyOneChild
                                            ? _c(
                                                "app-link",
                                                {
                                                  attrs: {
                                                    to: _vm.resolvePath(
                                                      _vm.onlyOneChild.route
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-menu-item",
                                                    {
                                                      attrs: {
                                                        index: _vm.resolvePath(
                                                          _vm.onlyOneChild.route
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("item", {
                                                        attrs: {
                                                          icon:
                                                            _vm.onlyOneChild
                                                              .icon ||
                                                            (itm && itm.icon),
                                                          title:
                                                            _vm.onlyOneChild
                                                              .menu_name,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      : _c(
                                          "el-submenu",
                                          {
                                            ref: "subMenu",
                                            refInFor: true,
                                            attrs: {
                                              index: _vm.resolvePath(itm.route),
                                              "popper-append-to-body": "",
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "title" },
                                              [
                                                itm
                                                  ? _c("item", {
                                                      attrs: {
                                                        icon: itm && itm.icon,
                                                        title: itm.menu_name,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              itm.children,
                                              function (child, index) {
                                                return _c("sidebar-item", {
                                                  key: index,
                                                  staticClass: "nest-menu",
                                                  attrs: {
                                                    "is-nest": true,
                                                    item: child,
                                                    "base-path":
                                                      _vm.resolvePath(
                                                        child.route
                                                      ),
                                                    isCollapse: _vm.isCollapse,
                                                  },
                                                })
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                  ],
                                  2
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isCollapse
                  ? [
                      _c(
                        "el-menu",
                        {
                          staticClass: "menuStyle2",
                          attrs: {
                            "default-active": _vm.activeMenu,
                            collapse: _vm.isCollapse,
                            "background-color": _vm.variables.menuBg,
                            "text-color": _vm.variables.menuText,
                            "unique-opened": true,
                            "active-text-color": "#ffffff",
                            "collapse-transition": false,
                            mode: "vertical",
                            "popper-class": "styleTwo",
                          },
                        },
                        [
                          _vm._l(_vm.menuList, function (route) {
                            return _c("sidebar-item", {
                              key: route.route,
                              staticClass: "style2",
                              attrs: { item: route, "base-path": route.route },
                            })
                          }),
                        ],
                        2
                      ),
                    ]
                  : _vm._e(),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }