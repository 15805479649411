import Layout from '@/layout';
import { roterPre } from '@/settings';
var deviseRouter = {
  path: "".concat(roterPre, "/devise"),
  name: 'devise',
  meta: {
    icon: '',
    title: '店铺装修'
  },
  alwaysShow: true,
  component: Layout,
  children: [{
    path: 'diy/index',
    name: 'NotificDiyation',
    meta: {
      title: '店铺装修',
      noCache: true,
      activeMenu: "".concat(roterPre, "/devise/diy/list")
    },
    component: function component() {
      return import('@/views/devise/index');
    }
  }, {
    path: 'diy/list',
    name: 'DecorationDiyation',
    meta: {
      title: '装修列表',
      noCache: true
    },
    component: function component() {
      return import('@/views/devise/list');
    }
  }]
};
export default deviseRouter;