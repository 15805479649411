var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传图片",
            "append-to-body": "",
            "modal-append-to-body": true,
            visible: _vm.uploadModal,
            width: _vm.isIframe ? "100%" : "800px",
            fullscreen: _vm.isIframe,
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadModal = $event
            },
            close: _vm.closed,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "main",
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传方式：", prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.radioChange(_vm.ruleForm.type)
                            },
                          },
                          model: {
                            value: _vm.ruleForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "type", $$v)
                            },
                            expression: "ruleForm.type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("本地上传"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("网络上传"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("扫码上传"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.ruleForm.type == 0 || _vm.ruleForm.type == 1,
                          expression:
                            "ruleForm.type == 0 || ruleForm.type == 1",
                        },
                      ],
                      attrs: { label: "上传至分组：", prop: "region" },
                    },
                    [
                      _c("el-cascader", {
                        staticClass: "form-width",
                        attrs: { props: _vm.props, options: _vm.categoryList },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.ruleForm.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "region", $$v)
                          },
                          expression: "ruleForm.region",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.type == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "网络图片：", prop: "region" } },
                        [
                          _c("el-input", {
                            staticClass: "form-width",
                            attrs: { placeholder: "请填写网络图片地址" },
                            model: {
                              value: _vm.webImgUrl,
                              callback: function ($$v) {
                                _vm.webImgUrl = $$v
                              },
                              expression: "webImgUrl",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "tq-text",
                              on: { click: _vm.getImg },
                            },
                            [_vm._v("提取照片")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleForm.type == 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "上传图片：", prop: "region" } },
                        [
                          _c("div", { staticClass: "acea-row" }, [
                            _c(
                              "div",
                              { staticClass: "uploadCont" },
                              [
                                _c(
                                  "el-upload",
                                  {
                                    ref: "upload",
                                    attrs: {
                                      action: _vm.fileUrl,
                                      "list-type": "picture-card",
                                      "on-change": _vm.fileChange,
                                      "file-list": _vm.ruleForm.imgList,
                                      "auto-upload": false,
                                      data: _vm.uploadData,
                                      headers: _vm.myHeaders,
                                      "before-upload": _vm.beforeUpload,
                                      multiple: true,
                                      limit: _vm.limit,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "file",
                                          fn: function (ref) {
                                            var file = ref.file
                                            return _c(
                                              "div",
                                              {
                                                attrs: { draggable: "false" },
                                                on: {
                                                  dragstart: function ($event) {
                                                    return _vm.handleDragStart(
                                                      $event,
                                                      file
                                                    )
                                                  },
                                                  dragover: function ($event) {
                                                    return _vm.handleDragOver(
                                                      $event,
                                                      file
                                                    )
                                                  },
                                                  dragenter: function ($event) {
                                                    return _vm.handleDragEnter(
                                                      $event,
                                                      file
                                                    )
                                                  },
                                                  dragend: function ($event) {
                                                    return _vm.handleDragEnd(
                                                      $event,
                                                      file
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass:
                                                    "el-upload-list__item-thumbnail",
                                                  attrs: {
                                                    src: file.url,
                                                    alt: "",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-error btndel",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleWebRemove(
                                                        file
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            )
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2372750312
                                    ),
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-plus",
                                      attrs: { slot: "default" },
                                      slot: "default",
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "tips" }, [
                                  _vm._v(
                                    "一次最多上传50张，建议上传图片最大宽度750px，不超过5MB；单次最多一次上传20张，仅支持jpeg、png格式，可拖拽调整上传顺序"
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleForm.type == 1
                    ? [
                        _c(
                          "div",
                          { staticClass: "img-box pl100" },
                          _vm._l(_vm.ruleForm.imgList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "pictrue",
                                attrs: { draggable: "false" },
                                on: {
                                  dragstart: function ($event) {
                                    return _vm.handleDragStart($event, item)
                                  },
                                  dragover: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleDragOver($event, item)
                                  },
                                  dragenter: function ($event) {
                                    return _vm.handleDragEnter($event, item)
                                  },
                                  dragend: function ($event) {
                                    return _vm.handleDragEnd($event, item)
                                  },
                                },
                              },
                              [
                                _c("img", { attrs: { src: item.url } }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-error btndel",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(index)
                                    },
                                  },
                                }),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleForm.type == 2
                    ? _c("div", { staticClass: "code-image" }, [
                        _c(
                          "div",
                          { staticClass: "left" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "上传至分组：",
                                  prop: "region",
                                },
                              },
                              [
                                _c("el-cascader", {
                                  staticClass: "form-width",
                                  attrs: {
                                    props: _vm.props,
                                    options: _vm.categoryList,
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: _vm.ruleForm.region,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "region", $$v)
                                    },
                                    expression: "ruleForm.region",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "二维码：", prop: "region" } },
                              [
                                _c("div", {
                                  ref: "qrCodeUrl",
                                  staticClass: "code",
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "trip" }, [
                                  _vm._v("扫描二维码，快速上传手机图片"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "trip-small" }, [
                                  _vm._v("建议使用手机浏览器"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "right" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small" },
                                on: { click: _vm.scanUploadGet },
                              },
                              [_vm._v("刷新图库")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "tip" }, [
                              _vm._v(
                                "刷新图库按钮，可显示移动端上传成功的图片"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "img-box" },
                              _vm._l(
                                _vm.ruleForm.imgList,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "pictrue",
                                      attrs: { draggable: "false" },
                                      on: {
                                        dragstart: function ($event) {
                                          return _vm.handleDragStart(
                                            $event,
                                            item
                                          )
                                        },
                                        dragover: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDragOver(
                                            $event,
                                            item
                                          )
                                        },
                                        dragenter: function ($event) {
                                          return _vm.handleDragEnter(
                                            $event,
                                            item
                                          )
                                        },
                                        dragend: function ($event) {
                                          return _vm.handleDragEnd($event, item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: { src: item.attachment_src },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clear } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitUpload } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }