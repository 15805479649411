var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("header-notice"),
                _vm._v(" "),
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" },
                }),
                _vm._v(" "),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("span", { staticClass: "el-dropdown-link fontSize" }, [
                _c("img", {
                  staticStyle: { height: "16px", width: "26px" },
                  attrs: {
                    src:
                      "https://www.greatmall.uz/uploads/lang-" +
                      _vm.local +
                      ".png",
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.localList, function (value, key) {
                  return _c(
                    "el-dropdown-item",
                    {
                      key: "lang-" + key,
                      attrs: { name: key },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.selectLang(key)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { height: "16px", width: "26px" },
                        attrs: {
                          src:
                            "https://www.greatmall.uz/uploads/lang-" +
                            key +
                            ".png",
                          alt: "",
                        },
                      }),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "platformLabel" }, [
            _vm._v(_vm._s(_vm.label.mer_name)),
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click", "hide-on-click": false },
            },
            [
              _c("span", { staticClass: "el-dropdown-link fontSize" }, [
                _vm._v("\n        " + _vm._s(_vm.adminInfo) + "\n        "),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.goUser($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v(_vm._s(_vm.$t("个人中心"))),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.goPassword($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v(_vm._s(_vm.$t("修改密码"))),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    { attrs: { divided: "" } },
                    [
                      _c(
                        "el-dropdown",
                        {
                          attrs: { placement: "right-start" },
                          on: { command: _vm.handleCommand },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.$t("菜单样式")))]),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "a" } },
                                [_vm._v(_vm._s(_vm.$t("标准")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "b" } },
                                [_vm._v(_vm._s(_vm.$t("分栏")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v(_vm._s(_vm.$t("退出"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }