import SettingMer from '@/libs/settingMer';
import ElementUI from 'element-ui';
import router from "../router";
import { roterPre } from '@/settings';
import Vue from 'vue';
function bindEvent(vm) {
  vm.$on('notice', function (data) {
    this.$notify.info({
      title: data.title || '消息',
      message: data.message,
      duration: 5000,
      onClick: function onClick() {
        console.log('click');
      }
    });
  });
}
function createWebScoket(token) {
  return new WebSocket("".concat(SettingMer.wsSocketUrl, "?type=mer&token=").concat(token));
}
function notice(token) {
  var ws = createWebScoket(token);
  var vm = new Vue();
  var ping;
  function send(type, data) {
    ws.send(JSON.stringify({
      type: type,
      data: data
    }));
  }
  ws.onopen = function () {
    vm.$emit('open');
    ping = setInterval(function () {
      send('ping');
    }, 10000);
  };
  ws.onmessage = function (res) {
    vm.$emit('message', res);
    var data = JSON.parse(res.data);
    if (data.status === 200) {
      vm.$emit(data.data.status, data.data.result);
    }
    if (data.type === 'notice') {
      var h = vm.$createElement;
      ElementUI.Notification({
        title: data.data.data.title,
        message: h('a', {
          style: 'color: teal'
        }, data.data.data.message),
        onClick: function onClick() {
          if (data.data.type === 'min_stock' || data.data.type === 'product') {
            router.push({
              path: "".concat(roterPre, "/product/list")
            });
          } else if (data.data.type === 'reply') {
            //评价
            router.push({
              path: "".concat(roterPre, "/product/reviews")
            });
          } else if (data.data.type === 'product_success') {
            //普通商品审核通过
            router.push({
              path: "".concat(roterPre, "/product/list?id=") + data.data.data.id + "&type=2"
            });
          } else if (data.data.type === 'product_fail') {
            //普通商品审核未通过
            router.push({
              path: "".concat(roterPre, "/product/list?id=") + data.data.data.id + "&type=7"
            });
          } else if (data.data.type === 'product_seckill_success') {
            //秒杀商品审核通过
            router.push({
              path: "".concat(roterPre, "/marketing/seckill/list?id=") + data.data.data.id + "&type=2"
            });
          } else if (data.data.type === 'product_seckill_fail') {
            //秒杀商品审核未通过
            router.push({
              path: "".concat(roterPre, "/marketing/seckill/list?id=") + data.data.data.id + "&type=7"
            });
          } else if (data.data.type === 'new_order') {
            router.push({
              path: "".concat(roterPre, "/order/list?id=") + data.data.data.id
            });
          } else if (data.data.type === 'new_refund_order') {
            router.push({
              path: "".concat(roterPre, "/order/refund?id=") + data.data.data.id
            });
          } else if (data.data.type === 'product_presell_success') {
            //预售商品审核通过
            router.push({
              path: "".concat(roterPre, "/marketing/presell/list?id=") + data.data.data.id + "&type=" + data.data.data.type + "&status=1"
            });
          } else if (data.data.type === 'product_presell_fail') {
            //预售商品审核未通过
            router.push({
              path: "".concat(roterPre, "/marketing/presell/list?id=") + data.data.data.id + "&type=" + data.data.data.type + "&status=-1"
            });
          } else if (data.data.type === 'product_group_success') {
            //拼团商品审核通过
            router.push({
              path: "".concat(roterPre, "/marketing/combination/combination_goods?id=") + data.data.data.id + "&status=1"
            });
          } else if (data.data.type === 'product_group_fail') {
            //拼团商品审核未通过
            router.push({
              path: "".concat(roterPre, "/marketing/combination/combination_goods?id=") + data.data.data.id + "&status=-1"
            });
          } else if (data.data.type === 'product_assist_success') {
            //助力商品审核通过
            router.push({
              path: "".concat(roterPre, "/marketing/assist/list?id=") + data.data.data.id + "&status=1"
            });
          } else if (data.data.type === 'product_assist_fail') {
            //助力商品审核未通过
            router.push({
              path: "".concat(roterPre, "/marketing/assist/list?id=") + data.data.data.id + "&status=-1"
            });
          } else if (data.data.type === 'broadcast_status_success') {
            //直播间审核通过
            router.push({
              path: "".concat(roterPre, "/marketing/studio/list?id=") + data.data.data.id + "&status=1"
            });
          } else if (data.data.type === 'broadcast_status_fail') {
            //直播间审核通过
            router.push({
              path: "".concat(roterPre, "/marketing/studio/list?id=") + data.data.data.id + "&status=-1"
            });
          } else if (data.data.type === 'goods_status_success') {
            //直播商品审核通过
            router.push({
              path: "".concat(roterPre, "/marketing/broadcast/list?id=") + data.data.data.id + "&status=1"
            });
          } else if (data.data.type === 'goods_status_fail') {
            //直播商品审核未通过
            router.push({
              path: "".concat(roterPre, "/marketing/broadcast/list?id=") + data.data.data.id + "&status=-1"
            });
          }
        }
      });
    }
  };
  ws.onclose = function (e) {
    vm.$emit('close', e);
    clearInterval(ping);
  };
  bindEvent(vm);
  return function () {
    ws.close();
  };
}
export default notice;