import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import request from '@/api/request';
import Vue from 'vue';
var fApi;
var unique = 1;
var uniqueId = function uniqueId() {
  return ++unique;
};
export default function modalForm(formRequestPromise) {
  var _this = this;
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var h = this.$createElement;
  return new Promise(function (resolve) {
    formRequestPromise.then(function (_ref) {
      var data = _ref.data;
      data.config.submitBtn = false;
      data.config.resetBtn = false;
      if (!data.config.form) data.config.form = {};
      if (!data.config.formData) data.config.formData = {};
      data.config.formData = _objectSpread(_objectSpread({}, data.config.formData), config.formData);
      data.config.form.labelWidth = '120px';
      data.config.global = {
        upload: {
          props: {
            onSuccess: function onSuccess(rep, file) {
              if (rep.status === 200) {
                file.url = rep.data.src;
              }
            }
          }
        }
      };
      data = Vue.observable(data);
      _this.$msgbox({
        title: data.title,
        customClass: config.class || 'modal-form',
        message: h('div', {
          class: 'common-form-create',
          key: uniqueId()
        }, [h('formCreate', {
          props: {
            rule: data.rule,
            option: data.config
          },
          on: {
            mounted: function mounted($f) {
              fApi = $f;
            }
          }
        })]),
        beforeClose: function beforeClose(action, instance, done) {
          var fn = function fn() {
            setTimeout(function () {
              instance.confirmButtonLoading = false;
            }, 500);
          };
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            fApi.submit(function (formData) {
              request[data.method.toLowerCase()](data.api, formData).then(function (res) {
                done();
                _this.$message.success(res.message || '提交成功');
                resolve(res);
              }).catch(function (err) {
                _this.$message.error(err.message || '提交失败');
                // reject(err)
              }).finally(function () {
                fn();
              });
            }, function () {
              return fn();
            });
          } else {
            fn();
            done();
          }
        }
      });
    }).catch(function (e) {
      _this.$message.error(e.message);
    });
  });
}