var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("sidebar", {
        staticClass: "sidebar-container",
        class: "leftBar" + _vm.sidebarWidth,
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: [
            "leftBar" + _vm.sidebarWidth,
            _vm.needTagsView ? "hasTagsView" : "",
          ],
        },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [
              _c("navbar"),
              _vm._v(" "),
              _vm.needTagsView ? _c("tags-view") : _vm._e(),
              _vm._v(" "),
              _vm.marginStatus == 1
                ? _c("div", { staticClass: "remained-main" }, [
                    _c("div", { staticClass: "remained" }, [
                      _c("span", { staticClass: "el-icon-warning" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "remained-text" }, [
                        _vm._v(
                          "店铺保证金金额不足，为避免影响您正常经营，请尽快补缴，否则" +
                            _vm._s(
                              _vm.days[0] == "-"
                                ? "店铺无法正常开启"
                                : _vm.days + "后会自动关闭店铺！"
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "pay-btn", on: { click: _vm.goPay } },
                        [_vm._v("[立即补缴]")]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("app-main"),
        ],
        1
      ),
      _vm._v(" "),
      _c("copy-right"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }