import request from "./request";
/**
 * @description 运费模板 -- 城市
 */
export function cityList() {
  return request.get('system/city/lst');
}
export function cityListV2(pid) {
  return request.get('v2/system/city/lst/' + pid);
}

/**
 * @description 运费模板 -- 列表
 */
export function templateListApi(data) {
  return request.get('store/shipping/lst', data);
}
/**
 * @description 运费模板 -- 新增
 */
export function templateCreateApi(data) {
  return request.post('store/shipping/create', data);
}
/**
 * @description 运费模板 -- 编辑
 */
export function templateUpdateApi(id, data) {
  return request.post("store/shipping/update/".concat(id), data);
}
/**
 * @description 运费模板 -- 详情
 */
export function templateDetailApi(id) {
  return request.get("/store/shipping/detail/".concat(id));
}
/**
 * @description 运费模板 -- 删除
 */
export function templateDeleteApi(id) {
  return request.delete("store/shipping/delete/".concat(id));
}