import Layout from '@/layout';
import { roterPre } from '@/settings';
var accountsRouter = {
  path: "".concat(roterPre, "/accounts"),
  name: 'accounts',
  meta: {
    icon: '',
    title: '财务'
  },
  alwaysShow: true,
  component: Layout,
  children: [{
    path: 'reconciliation',
    name: 'AccountsReconciliation',
    meta: {
      title: '财务对账',
      noCache: true
    },
    component: function component() {
      return import('@/views/accounts/reconciliation/index');
    }
  }, {
    path: 'statement',
    name: 'AccountsStatement',
    meta: {
      title: '财务账单',
      noCache: true
    },
    component: function component() {
      return import('@/views/accounts/statement/index');
    }
  }, {
    path: 'reconciliation/order/:id',
    name: 'ReconciliationOrder',
    component: function component() {
      return import('@/views/accounts/reconciliation/record');
    },
    meta: {
      title: '查看订单',
      noCache: true,
      activeMenu: "".concat(roterPre, "/accounts/reconciliation")
    },
    hidden: true
  }, {
    path: 'capitalFlow',
    name: 'AccountsCapitalFlow',
    meta: {
      title: '资金流水',
      noCache: true
    },
    component: function component() {
      return import('@/views/accounts/capitalFlow/index');
    }
  }, {
    path: 'transManagement',
    name: 'AccountsTransManagement',
    meta: {
      title: '对账管理',
      noCache: true
    },
    component: function component() {
      return import('@/views/accounts/transManage/index');
    }
  }, {
    path: 'payType',
    name: 'AccountsPayType',
    meta: {
      title: '收款方式',
      noCache: true
    },
    component: function component() {
      return import('@/views/accounts/payType');
    }
  }]
};
export default accountsRouter;