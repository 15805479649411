import Cookies from "js-cookie";
// 请求接口地址 如果没有配置自动获取当前网址路径
// let url = "";
var url = "https://gm.test.zhongwugou.com";
// let url = "https://www.greatmall.uz"
// let url = "http://192.168.1.191:8329"

// const VUE_APP_API_URL = url
var VUE_APP_API_URL = url || process.env.VUE_APP_BASE_API || "".concat(location.origin);
var VUE_APP_WS_URL = process.env.VUE_APP_WS_URL || (location.protocol === 'https:' ? 'wss' : 'ws') + ':' + location.hostname;
var login_title = Cookies.get('MerInfo') ? JSON.parse(Cookies.get('MerInfo')).login_title : '';
var SettingMer = {
  // 服务器地址
  httpUrl: VUE_APP_API_URL,
  // 接口请求地址
  https: VUE_APP_API_URL + '/mer',
  https2: VUE_APP_API_URL,
  // socket连接
  wsSocketUrl: VUE_APP_WS_URL,
  // 路由标题
  title: login_title || '加载中...'
};
export default SettingMer;