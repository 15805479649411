import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { localRead } from '@/libs/util';
import customZh from "./lang/zh";
import customRu from "./lang/ru";
import customEn from "./lang/en";
import customUz from "./lang/uz";
import zhCnLocale from 'element-ui/src/locale/lang/zh-CN';
import enUsLocale from 'element-ui/src/locale/lang/en';
import zhTwLocale from 'element-ui/src/locale/lang/ru-RU';
import uzLocale from 'element-ui/src/locale/lang/uz-UZ';
import store from '@/store';
Vue.use(VueI18n);
console.log(store.getters.local);
// 自动根据浏览器系统语言设置语言
// const navLang = navigator.language;
// const localLang = navLang === 'zh-CN' || navLang === 'uz-UZ' ? navLang : false;
// let lang = localLang || localRead('local') || 'uz-UZ';

// Vue.config.lang = lang;
// this.$store.state.app.local
// vue-i18n 6.x+写法
Vue.locale = function () {};
var messages = {
  'zh-CN': Object.assign(zhCnLocale, customZh),
  'ru-RU': Object.assign(zhTwLocale, customRu),
  'en': Object.assign(enUsLocale, customEn),
  'uz-UZ': Object.assign(uzLocale, customUz)
};
var i18n = new VueI18n({
  locale: store.getters.local,
  messages: messages,
  silentTranslationWarn: true
});
export default i18n;

// vue-i18n 5.x写法
// Vue.locale('zh-CN', Object.assign(zhCnLocale, customZhCn))
// Vue.locale('en-US', Object.assign(zhTwLocale, customZhTw))
// Vue.locale('zh-TW', Object.assign(enUsLocale, customEnUs))