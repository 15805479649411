import Layout from '@/layout';
import { roterPre } from '@/settings';
var configRouter = {
  path: "".concat(roterPre, "/config"),
  name: 'system_config',
  meta: {
    icon: 'dashboard',
    title: '系统配置'
  },
  alwaysShow: true,
  // 一直显示根路由
  component: Layout,
  children: [{
    path: 'picture',
    name: 'system_config_picture',
    meta: {
      title: '素材管理'
    },
    component: function component() {
      return import('@/views/system/config/picture');
    }
  }, {
    path: 'service',
    name: 'Service',
    meta: {
      title: '客服管理'
    },
    component: function component() {
      return import('@/views/system/service/index');
    }
  }, {
    path: 'guarantee',
    name: 'Guarantee',
    meta: {
      title: '保障服务',
      noCache: true
    },
    component: function component() {
      return import('@/views/system/guarantee/index');
    }
  }, {
    path: 'freight',
    name: 'Freight',
    meta: {
      title: '物流设置'
    },
    component: function component() {
      return import('@/views/system/freight/index');
    },
    children: [{
      path: 'shippingTemplates',
      name: 'ShippingTemplates',
      meta: {
        title: '运费模板',
        noCache: true
      },
      component: function component() {
        return import('@/views/system/freight/shippingTemplates');
      }
    }, {
      path: 'express',
      name: 'Express',
      meta: {
        title: '物流公司',
        noCache: true
      },
      component: function component() {
        return import('@/views/system/freight/express/index');
      }
    }]
  }]
};
export default configRouter;