import Cookies from 'js-cookie';
import { localSave, localRead } from '@/libs/util';
var state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : false,
    withoutAnimation: false
  },
  local: localRead('local'),
  device: 'desktop',
  size: Cookies.get('size') || 'medium'
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_SIZE: function SET_SIZE(state, size) {
    state.size = size;
    Cookies.set('size', size);
  },
  SET_LOCAL: function SET_LOCAL(state, lang) {
    localSave('local', lang);
    state.local = lang;
  }
};
var actions = {
  setLocal: function setLocal(_ref, lang) {
    var commit = _ref.commit;
    console.log(lang, lang);
    commit('SET_LOCAL', lang);
  },
  toggleSideBar: function toggleSideBar(_ref2) {
    var commit = _ref2.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref3, _ref4) {
    var commit = _ref3.commit;
    var withoutAnimation = _ref4.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref5, device) {
    var commit = _ref5.commit;
    commit('TOGGLE_DEVICE', device);
  },
  setSize: function setSize(_ref6, size) {
    var commit = _ref6.commit;
    commit('SET_SIZE', size);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};