import Layout from '@/layout';
import { roterPre } from '@/settings';
var groupRouter = {
  path: "".concat(roterPre, "/group"),
  name: 'system_group',
  meta: {
    icon: 'dashboard',
    title: '组合数据'
  },
  alwaysShow: true,
  component: Layout,
  children: [{
    path: 'list',
    name: 'system_group_lst',
    meta: {
      title: '组合数据'
    },
    component: function component() {
      return import('@/views/system/groupData/list');
    }
  }, {
    path: 'data/:id?',
    name: 'system_group_data',
    meta: {
      title: '组合数据列表'
    },
    component: function component() {
      return import('@/views/system/groupData/data');
    }
  }, {
    path: 'config/:id?',
    name: 'system_group_data',
    meta: {
      title: '组合数据列表'
    },
    component: function component() {
      return import('@/views/system/groupData/data');
    }
  }, {
    path: 'topic/:id?',
    name: 'system_group_topic',
    meta: {
      title: '专场列表'
    },
    component: function component() {
      return import('@/views/system/topic/data');
    }
  }]
};
export default groupRouter;