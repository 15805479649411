import Layout from '@/layout';
import { roterPre } from '@/settings';
var orderRouter = {
  path: "".concat(roterPre, "/order"),
  name: 'order',
  meta: {
    icon: 'dashboard',
    title: '订单'
  },
  alwaysShow: true,
  component: Layout,
  redirect: 'noRedirect',
  children: [{
    path: 'list',
    name: 'OrderList',
    meta: {
      title: '订单管理'
    },
    component: function component() {
      return import('@/views/order/index');
    }
  }, {
    path: 'refund',
    name: 'OrderRefund',
    meta: {
      title: '退款单'
    },
    component: function component() {
      return import('@/views/order/orderRefund/index');
    }
  }, {
    path: 'invoice',
    name: 'OrderInvoice ',
    meta: {
      title: '发票管理'
    },
    component: function component() {
      return import('@/views/order/orderInvoice/index');
    }
  }, {
    path: 'cancellation',
    name: 'OrderCancellation',
    meta: {
      title: '核销订单'
    },
    component: function component() {
      return import('@/views/order/orderCancellate/index');
    }
  }, {
    path: 'collect1688',
    name: 'Order1688',
    meta: {
      title: '1688采集'
    },
    component: function component() {
      return import('@/views/order/collect/1688');
    }
  }, {
    path: 'tbCollect',
    name: 'OrdertbCollect',
    meta: {
      title: '淘宝采集'
    },
    component: function component() {
      return import('@/views/order/collect/tb');
    }
  }]
};
export default orderRouter;