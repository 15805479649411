import Layout from '@/layout';
import { roterPre } from '@/settings';
var productRouter = {
  path: "".concat(roterPre, "/product"),
  name: 'product',
  component: Layout,
  meta: {
    icon: 'dashboard',
    title: '商品管理'
  },
  alwaysShow: true,
  redirect: 'noRedirect',
  children: [{
    path: 'classify',
    name: 'ProductClassify',
    meta: {
      title: '商品分类',
      noCache: true
    },
    component: function component() {
      return import('@/views/product/productClassify');
    }
  }, {
    path: 'attr',
    name: "ProductAttr",
    meta: {
      title: '商品规格',
      noCache: true
    },
    component: function component() {
      return import('@/views/product/productAttr');
    }
  }, {
    path: 'label',
    name: "ProductLabel",
    meta: {
      title: '商品标签',
      noCache: true
    },
    component: function component() {
      return import('@/views/product/productLabel');
    }
  }, {
    path: 'list',
    name: "ProductList",
    meta: {
      title: '商品列表',
      noCache: true
    },
    component: function component() {
      return import('@/views/product/productList');
    }
  }, {
    path: 'list/addProduct/:id?/:edit?',
    component: function component() {
      return import('@/views/product/addProduct/index');
    },
    name: 'AddProduct',
    meta: {
      title: '商品添加',
      noCache: true,
      activeMenu: "".concat(roterPre, "/product/list")
    },
    hidden: true
  }, {
    path: 'reviews',
    name: 'ProductReviews',
    meta: {
      title: '商品评论',
      noCache: true,
      activeMenu: "".concat(roterPre, "/product/reviews")
    },
    component: function component() {
      return import('@/views/product/Reviews/index');
    }
  }, {
    path: 'specs',
    name: 'ProductSpecs',
    meta: {
      title: '商品参数',
      noCache: true
    },
    component: function component() {
      return import('@/views/product/specs/list.vue');
    }
  }, {
    path: 'specs/create/:id?',
    name: 'ProductSpecsCreate',
    meta: {
      title: '添加参数模板',
      noCache: true,
      activeMenu: "".concat(roterPre, "/product/specs")
    },
    component: function component() {
      return import('@/views/product/specs/create.vue');
    }
  }]
};
export default productRouter;