import Layout from '@/layout';
import { roterPre } from '@/settings';
var exportFileRouter = {
  path: "".concat(roterPre, "/export"),
  name: 'exportFile',
  meta: {
    icon: '',
    title: '导出文件'
  },
  alwaysShow: true,
  component: Layout,
  children: [{
    path: 'list',
    name: 'exportList',
    meta: {
      title: '导出文件'
    },
    component: function component() {
      return import('@/views/exportFile/index');
    }
  }]
};
export default exportFileRouter;