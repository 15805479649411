import "core-js/modules/web.dom.iterable";
import axios from 'axios';
import store from '@/store';
import SettingMer from '@/libs/settingMer';
var instance = axios.create({
  baseURL: SettingMer.https,
  timeout: 60000
});
var defaultOpt = {
  login: true
};
function baseRequest(options) {
  var token = store.getters.token;
  var local = store.getters.local;
  var headers = options.headers || {};
  if (token) {
    headers['X-Token'] = token;
    if (local) {
      if (local == 'zh-CN') headers['ptlangue'] = 'zh';
      if (local == 'ru-RU') headers['ptlangue'] = 'ru';
      if (local == 'en') headers['ptlangue'] = 'en';
      if (local == 'uz-UZ') headers['ptlangue'] = 'uz';
    }
    options.headers = headers;
  }
  return new Promise(function (resolve, reject) {
    instance(options).then(function (res) {
      var data = res.data || {};
      if (res.status !== 200) {
        return reject({
          message: '请求失败',
          res: res,
          data: data
        });
      }
      if ([410000, 410001, 410002, 40000].indexOf(data.status) !== -1) {
        store.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      } else if (data.status === 200) {
        return resolve(data, res);
      } else {
        return reject({
          message: data.message,
          res: res,
          data: data
        });
      }
    }).catch(function (message) {
      return reject({
        message: message
      });
    });
  });
}

/**
 * http 请求基础类
 * 参考文档 https://www.kancloud.cn/yunye/axios/234845
 *
 */
var request = ['post', 'put', 'patch', 'delete'].reduce(function (request, method) {
  /**
   *
   * @param url string 接口地址
   * @param data object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = function (url) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return baseRequest(Object.assign({
      url: url,
      data: data,
      method: method
    }, defaultOpt, options));
  };
  return request;
}, {});
['get', 'head'].forEach(function (method) {
  /**
   *
   * @param url string 接口地址
   * @param params object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = function (url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return baseRequest(Object.assign({
      url: url,
      params: params,
      method: method
    }, defaultOpt, options));
  };
});
export default request;