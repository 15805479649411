//
//
//
//
//
//
//
//
//
//
//
//
//

import defaultSettings from '@/settings';
var title = defaultSettings.title;
import Cookies from 'js-cookie';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    },
    sideBar1: {
      type: Boolean,
      required: false
    }
  },
  data: function data() {
    return {
      title: title,
      logo: JSON.parse(Cookies.get('MerInfo')).menu_logo,
      slogo: JSON.parse(Cookies.get('MerInfo')).menu_slogo
    };
  }
};