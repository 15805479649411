import Layout from '@/layout';
import { roterPre } from '@/settings';
var userRouter = {
  path: "".concat(roterPre, "/user"),
  name: 'user',
  meta: {
    icon: 'dashboard',
    title: '用户管理'
  },
  alwaysShow: true,
  component: Layout,
  redirect: 'noRedirect',
  children: [{
    path: 'label',
    name: 'UserLabel',
    meta: {
      title: '用户标签',
      noCache: true
    },
    component: function component() {
      return import('@/views/user/group');
    }
  }, {
    path: 'maticlabel',
    name: 'UserAutoLabel',
    meta: {
      title: '自动标签',
      noCache: true
    },
    component: function component() {
      return import('@/views/user/label');
    }
  }, {
    path: 'list',
    name: 'UserList',
    meta: {
      title: '用户列表',
      noCache: true
    },
    component: function component() {
      return import('@/views/user/list');
    }
  }, {
    path: 'searchRecord',
    name: 'searchRecord',
    meta: {
      title: '用户搜索记录',
      noCache: true
    },
    component: function component() {
      return import('@/views/user/search');
    }
  }, {
    path: 'memberRecord',
    name: 'memberRecord',
    meta: {
      title: '付费会员记录',
      noCache: true
    },
    component: function component() {
      return import('@/views/user/member/record');
    }
  }]
};
export default userRouter;