//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { stationNewsList } from '@/api/system';
import { roterPre } from "@/settings";
export default {
  name: 'headerNotice',
  data: function data() {
    return {
      activeName: 'second',
      messageList: [],
      needList: [],
      count: 0,
      tabPosition: 'right',
      roterPre: roterPre
    };
  },
  computed: {},
  watch: {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
    },
    goDetail: function goDetail(item) {
      item.is_read = 1;
      console.log(this.$router);
      this.$router.push({
        path: this.roterPre + "/station/notice",
        query: {
          id: item.notice_log_id
        }
      });
    },
    // 列表
    getList: function getList() {
      var _this = this;
      stationNewsList({
        is_read: 0
      }).then(function (res) {
        _this.messageList = res.data.list;
        _this.count = res.data.count;
      }).catch(function (res) {});
    },
    HandleDelete: function HandleDelete(i) {
      this.messageList.splice(i, 1);
    }
  }
};