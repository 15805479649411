import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.function.name";
import * as qiniu from 'qiniu-js';
import Cos from 'cos-js-sdk-v5';
import axios from 'axios';
import { uploadVideoOfLocal } from '@/api/product';
var sign = function sign(method, publicKey, privateKey, md5, contentType, date, bucketName, fileName) {
  var CryptoJS = require('crypto-js'); // 这里使用了crypto-js加密算法库，安装方法会在后面说明
  var CanonicalizedResource = "/".concat(bucketName, "/").concat(fileName);
  var StringToSign = method + '\n' + md5 + '\n' + contentType + '\n' + date + '\n' + CanonicalizedResource; // 此处的md5以及date是可选的，contentType对于PUT请求是可选的，对于POST请求则是必须的
  var Signature = CryptoJS.HmacSHA1(StringToSign, privateKey);
  Signature = CryptoJS.enc.Base64.stringify(Signature);
  return 'UCloud' + ' ' + publicKey + ':' + Signature;
};
export default {
  videoUpload: function videoUpload(config) {
    var result;
    switch (config.type) {
      case 'COS':
        result = this.cosUpload(config.evfile, config.res.data, config.uploading);
        break;
      case 'OSS':
        result = this.ossHttp(config.evfile, config.res.data, config.uploading);
        break;
      case 'OBS':
        result = this.obsHttp(config.evfile, config.res.data, config.uploading);
        break;
      case 'US3':
        result = this.us3Http(config.evfile, config.res.data, config.uploading);
        break;
      case 'JDOSS':
        result = this.uploadMp4ToLocal(config.evfile, config.res.data, config.uploading);
        break;
      case 'CTOSS':
        result = this.obsHttp(config.evfile, config.res.data, config.uploading);
        break;
      case 'QINIU':
        result = this.qiniuHttp(config.evfile, config.res, config.uploading);
        break;
      case 'local':
        result = this.uploadMp4ToLocal(config.evfile, config.res.data, config.uploading);
        break;
    }
    return result;
  },
  cosUpload: function cosUpload(file, config, uploading) {
    var cos = new Cos({
      getAuthorization: function getAuthorization(options, callback) {
        callback({
          TmpSecretId: config.credentials.tmpSecretId,
          // 临时密钥的 tmpSecretId
          TmpSecretKey: config.credentials.tmpSecretKey,
          // 临时密钥的 tmpSecretKey
          XCosSecurityToken: config.credentials.sessionToken,
          // 临时密钥的 sessionToken
          ExpiredTime: config.expiredTime // 临时密钥失效时间戳，是申请临时密钥时，时间戳加 durationSeconds
        });
      }
    });
    var fileObject = file.target.files[0];
    var Key = fileObject.name;
    var pos = Key.lastIndexOf('.');
    var suffix = '';
    if (pos !== -1) {
      suffix = Key.substring(pos);
    }
    var filename = new Date().getTime() + suffix;
    return new Promise(function (resolve, reject) {
      cos.sliceUploadFile({
        Bucket: config.bucket,
        /* 必须 */
        Region: config.region,
        /* 必须 */
        Key: filename,
        /* 必须 */
        Body: fileObject,
        // 上传文件对象
        onProgress: function onProgress(progressData) {
          uploading(progressData);
        }
      }, function (err, data) {
        if (err) {
          reject({
            msg: err
          });
        } else {
          resolve({
            url: config.cdn ? config.cdn + filename : 'http://' + data.Location,
            ETag: data.ETag
          });
        }
      });
    });
  },
  obsHttp: function obsHttp(file, res, videoIng) {
    var fileObject = file.target.files[0];
    var Key = fileObject.name;
    var pos = Key.lastIndexOf('.');
    var suffix = '';
    if (pos !== -1) {
      suffix = Key.substring(pos);
    }
    var filename = new Date().getTime() + suffix;
    var formData = new FormData();
    var data = res.data;
    // 注意formData里append添加的键的大小写
    formData.append('key', filename);
    formData.append('AccessKeyId', data.accessid);
    formData.append('policy', data.policy);
    formData.append('signature', data.signature);
    formData.append('file', fileObject);
    formData.append('success_action_status', 200);
    var url = data.host;
    var fileUrl = url + '/' + filename;
    videoIng(true, 100);
    return new Promise(function (resolve, reject) {
      axios.defaults.withCredentials = false;
      axios.post(url, formData).then(function () {
        videoIng(false, 0);
        resolve({
          url: data.cdn ? data.cdn + '/' + filename : fileUrl
        });
      }).catch(function (res) {
        reject({
          msg: res
        });
      });
    });
  },
  us3Http: function us3Http(file, res, videoIng) {
    var fileObject = file.target.files[0];
    var Key = fileObject.name;
    var pos = Key.lastIndexOf('.');
    var suffix = '';
    if (pos !== -1) {
      suffix = Key.substring(pos);
    }
    var filename = new Date().getTime() + suffix;
    var data = res.data;
    var auth = sign('PUT', data.accessid, data.secretKey, '', fileObject.type, '', data.storageName, filename);
    return new Promise(function (resolve, reject) {
      axios.defaults.withCredentials = false;
      var url = "https://".concat(data.storageName, ".cn-bj.ufileos.com/").concat(filename);
      axios.put(url, fileObject, {
        headers: {
          'Authorization': auth,
          'content-type': fileObject.type
        }
      }).then(function (res) {
        videoIng(false, 0);
        resolve({
          url: data.cdn ? data.cdn + '/' + filename : url
        });
      }).catch(function (res) {
        reject({
          msg: res
        });
      });
    });
  },
  cosHttp: function cosHttp(evfile, res, videoIng) {
    // 腾讯云
    // 对更多字符编码的 url encode 格式
    var camSafeUrlEncode = function camSafeUrlEncode(str) {
      return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\*/g, '%2A');
    };
    var fileObject = evfile.target.files[0];
    var Key = fileObject.name;
    var pos = Key.lastIndexOf('.');
    var suffix = '';
    if (pos !== -1) {
      suffix = Key.substring(pos);
    }
    var filename = new Date().getTime() + suffix;
    var data = res.data;
    var XCosSecurityToken = data.credentials.sessionToken;
    var url = data.url + camSafeUrlEncode(filename).replace(/%2F/g, '/');
    var xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    XCosSecurityToken && xhr.setRequestHeader('x-cos-security-token', XCosSecurityToken);
    xhr.upload.onprogress = function (e) {
      var progress = Math.round(e.loaded / e.total * 10000) / 100;
      videoIng(true, progress);
    };
    return new Promise(function (resolve, reject) {
      xhr.onload = function () {
        if (/^2\d\d$/.test('' + xhr.status)) {
          var ETag = xhr.getResponseHeader('etag');
          videoIng(false, 0);
          resolve({
            url: data.cdn ? data.cdn + camSafeUrlEncode(filename).replace(/%2F/g, '/') : url,
            ETag: ETag
          });
        } else {
          reject({
            msg: '文件 ' + filename + ' 上传失败，状态码：' + xhr.statu
          });
        }
      };
      xhr.onerror = function () {
        reject({
          msg: '文件 ' + filename + '上传失败，请检查是否没配置 CORS 跨域规'
        });
      };
      xhr.send(fileObject);
      xhr.onreadystatechange = function () {};
    });
  },
  ossHttp: function ossHttp(evfile, res, videoIng) {
    var fileObject = evfile.target.files[0];
    var file = fileObject.name;
    var pos = file.lastIndexOf('.');
    var suffix = '';
    if (pos !== -1) {
      suffix = file.substring(pos);
    }
    var filename = new Date().getTime() + suffix;
    var formData = new FormData();
    var data = res.data;
    // 注意formData里append添加的键的大小写
    formData.append('key', filename); // 存储在oss的文件路径
    formData.append('OSSAccessKeyId', data.accessid); // accessKeyId
    formData.append('policy', data.policy); // policy
    formData.append('Signature', data.signature); // 签名
    // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
    formData.append('file', fileObject);
    formData.append('success_action_status', 200); // 成功后返回的操作码
    var url = data.host;
    var fileUrl = url + '/' + filename;
    videoIng(true, 100);
    return new Promise(function (resolve, reject) {
      axios.defaults.withCredentials = false;
      axios.post(url, formData).then(function () {
        // that.progress = 0;
        videoIng(false, 0);
        resolve({
          url: data.cdn ? data.cdn + '/' + filename : fileUrl
        });
      }).catch(function (res) {
        reject({
          msg: res
        });
      });
    });
  },
  qiniuHttp: function qiniuHttp(evfile, res, videoIng) {
    var uptoken = res.data && res.data.token || res.token;
    var file = evfile.target.files[0]; // Blob 对象，上传的文件
    var Key = file.name; // 上传后文件资源名以设置的 key 为主，如果 key 为 null 或者 undefined，则文件资源名会以 hash 值作为资源名。
    var pos = Key.lastIndexOf('.');
    var suffix = '';
    if (pos !== -1) {
      suffix = Key.substring(pos);
    }
    var filename = new Date().getTime() + suffix;
    var fileUrl = res.data && res.data.domain + '/' + filename || res.domain + '/' + filename;
    var config = {
      useCdnDomain: true
    };
    var putExtra = {
      fname: '',
      // 文件原文件名
      params: {},
      // 用来放置自定义变量
      mimeType: null // 用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
    };
    var observable = qiniu.upload(file, filename, uptoken, putExtra, config);
    return new Promise(function (resolve, reject) {
      observable.subscribe({
        next: function next(result) {
          var progress = Math.round(result.total.loaded / result.total.size);
          videoIng(true, progress);
          // 主要用来展示进度
        },
        error: function error(errResult) {
          // 失败报错信息
          reject({
            msg: errResult
          });
        },
        complete: function complete(result) {
          // 接收成功后返回的信息
          videoIng(false, 0);
          var cdn = res.data && res.data.cdn || res.cdn;
          resolve({
            url: cdn ? cdn + '/' + filename : fileUrl
          });
        }
      });
    });
  },
  jdossHttp: function jdossHttp(evfile, res, videoIng) {
    var fileObject = evfile.target.files[0];
    console.log(evfile);
    var Key = fileObject.name;
    var pos = Key.lastIndexOf('.');
    var suffix = '';
    if (pos !== -1) {
      suffix = Key.substring(pos);
    }
    var filename = new Date().getTime() + suffix;
    var formData = new FormData();
    var data = res;
    //formData.append('Key', fileObject.name)
    formData.append('key', filename);
    formData.append('x-amz-credential', data.credential);
    formData.append('x-amz-algorithm', 'AWS4-HMAC-SHA256');
    formData.append('x-amz-date', data.expiration);
    formData.append('policy', data.policy);
    formData.append('x-amz-signature', data.signature);
    formData.append('file', fileObject);
    return new Promise(function (resolve, reject) {
      axios.defaults.withCredentials = false;
      var url = "https://".concat(data.storageName, ".s3.").concat(data.storageRegion, ".jdcloud-oss.com");
      axios.post(url, formData).then(function (res) {
        console.log(1, res);
        videoIng(false, 0);
        resolve({
          url: data.cdn ? data.cdn + '/' + filename : url
        });
      }).catch(function (res) {
        reject({
          msg: res
        });
      });
    });
  },
  // 本地上传
  uploadMp4ToLocal: function uploadMp4ToLocal(evfile, res, videoIng) {
    var fileObject = evfile.target.files[0]; // 获取的文件对象
    var formData = new FormData();
    formData.append('file', fileObject);
    videoIng(true, 100);
    return uploadVideoOfLocal(formData);
  }
};