var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden
    ? _c(
        "div",
        { class: { menuTwo: _vm.isCollapse } },
        [
          [
            _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
            (!_vm.onlyOneChild.children ||
              _vm.onlyOneChild.noShowingChildren) &&
            !_vm.item.alwaysShow
              ? [
                  _vm.onlyOneChild
                    ? _c(
                        "app-link",
                        {
                          attrs: {
                            to: _vm.resolvePath(_vm.onlyOneChild.route),
                          },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              class: {
                                "submenu-title-noDropdown": !_vm.isNest,
                              },
                              attrs: {
                                index: _vm.resolvePath(_vm.onlyOneChild.route),
                              },
                            },
                            [
                              _vm.sideBar1 &&
                              (!_vm.item.children ||
                                _vm.item.children.length <= 1)
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "el-submenu__title",
                                        class: {
                                          titles: _vm.level == 0,
                                          hide:
                                            !_vm.sideBar1 && !_vm.isCollapse,
                                        },
                                      },
                                      [
                                        _c("i", {
                                          class:
                                            "menu-icon el-icon-" +
                                            _vm.item.icon,
                                        }),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.onlyOneChild.menu_name)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                : _c("item", {
                                    attrs: {
                                      icon:
                                        _vm.onlyOneChild.icon ||
                                        (_vm.item && _vm.item.icon),
                                      title: _vm.onlyOneChild.menu_name,
                                    },
                                  }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              : _c(
                  "el-submenu",
                  {
                    ref: "subMenu",
                    class: { subMenu2: _vm.sideBar1 },
                    attrs: {
                      "popper-class": _vm.sideBar1 ? "styleTwo" : "",
                      index: _vm.resolvePath(_vm.item.route),
                      "popper-append-to-body": "",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "title" },
                      [
                        _vm.item
                          ? _c("item", {
                              attrs: {
                                icon: _vm.item && _vm.item.icon,
                                title: _vm.item.menu_name,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.item.children, function (child, index) {
                      return _c("sidebar-item", {
                        key: index,
                        staticClass: "nest-menu",
                        attrs: {
                          level: _vm.level + 1,
                          "is-nest": true,
                          item: child,
                          "base-path": _vm.resolvePath(child.route),
                        },
                      })
                    }),
                  ],
                  2
                ),
          ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }