var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.openVersion !== "0"
    ? _c("div", { staticClass: "ivu-global-footer i-copyright" }, [_vm._m(0)])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ivu-global-footer-copyright" }, [
      _vm._v("Copyright © 2023 "),
      _c(
        "a",
        { attrs: { href: "https://wwww.suyunweb.net", target: "_blank" } },
        [_vm._v("浙江速云")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }